.container-global {
    padding: 20px 18px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #005877;
    border-Radius: 4px;
    color: #ffffff;
    font-family: raleway;
}

.container-social-proof-block {
    display: flex;
    flex-direction: column;
}

.social-proof-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.social-proof-title h2 {
    font-family: raleway;
    font-size: 16px;
}

.social-proof-block{
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
} 

.social-proof-block-heading  {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0;
    font-family: raleway;
    display: inline-block;
}

.social-proof-block-label {
    font-size: 20px;
    font-weight: bold;
    margin-left: 3px;
    font-family: raleway;
    display: inline-block;
}

.social-proof-block-description{
    font-size: 16px;
    font-family: raleway;
    margin-left: 5px;
    display: inline-block;
}
@media all and (min-width: 768px) {
    .container-global {
        padding: 30px 28px;
        max-width: 90%;
    }
    .container-social-proof-block {
        flex-direction: row;
        justify-content: space-around;
    }
    .social-proof-block{
        margin-bottom: 0;
        display: block; 
      } 
    .social-proof-title {
        max-width: 35%;
    }
    .social-proof-title h2 {
        font-size: 24px;
    }
    .social-proof-block-label {
        margin-left: 4px;
    }
    .social-proof-block-description {
        margin-left: 0;
    }
}

@media all and (min-width: 992px) {
    .social-proof-title {
        max-width: 30%;
    }
    .social-proof-block-heading {
        font-size: 48px;
    }
    .social-proof-block-label {
        font-size: 36px;
        margin-left: 6px;
    }
    .social-proof-block-description {
        font-size: 20px;
    }
}