#rejoignez-une-communauté-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: raleway ;
    width: 90%;

    .first-block-buttons-container-inscription-button{
        font-size: 17px ;
        margin-right: 30px ;
        font-family: raleway-bold ;
    }

    .first-block-buttons-container-projets-button{
        font-size: 17px ;
        margin-right: 30px ;
        font-family: raleway-bold ;
        color: #005877;
        background-color: transparent;
        border: 2px solid #005877 ;
    }
}

.first-block{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #E0E8EB;
    border-radius: 4px 0px 0px 4px;
    padding: 30px;
    text-align: left;
    width: 50%;
}
.first-block-empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #E0E8EB;
    border-radius: 4px 0px 0px 4px;
    padding: 30px;
    text-align: left;
    width: 50%;
}

.first-block-title{
    font-weight: bold;
    font-size: 20px;
    font-family: raleway;
    color:black;
}

.first-block-subtitle{
    font-size: 16px;
    color: #000;
}

.first-block-buttons-container{
    display: flex;
    flex-wrap: wrap;
}


.second-block{
    background-color:#005877;
    color: #ffffff;
    border-radius: 0px 4px 4px 0px;
    padding: 30px;
    width: 50%;
    text-align: left;
    font-size: 16px;
}

.bullet-paragraph-base{
    display: flex;
    align-items: baseline;
}

.bullet-paragraph-base-margin{
    margin-bottom: 12px;
}

.bullet-paragraph-icon{
    color:#ffffff
}

.bullet-paragraph-text{
    margin-left: 9px;
}

/*media queries*/

@media all and (max-width:768px){
    #rejoignez-une-communauté-container{
        width: 100%;

        .first-block-buttons-container-projets-button{
          margin-top: 20px;
        }
    }
}

@media all and (max-width: 992px){
    .first-block, .first-block-empty{
        width: 100%;
        border-radius: 4px 4px 0px 0px ;
    }

    .second-block{
        width: 100%;
        border-radius: 0px  0px 4px 4px;
    }
}