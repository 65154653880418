.container-bandeau-without-children, .container-bandeau-with-children  {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: end; 
    justify-content: start;
    margin-bottom: 25px;
    background-image: none;
}

.container-bandeau-section {
    margin-top: 1vh;
}

.container-text-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.container-text-title {
    font-size: 28px;
    font-weight: bold;
    color: black;
}

.container-text-paragraph {
    font-size: 16px;
    font-family:"raleway";
    padding-bottom: 5px;
    margin-bottom: 25px;
    color: black;
}

.margin-bottom-without-p {
    margin-bottom: 30px;
}
.margin-bottom-with-p {
    margin-bottom: 2vh;
}

.container-bloc-text-button {
   color:black !important;
   padding: 10px 34px 10px 34px !important;
   font-size: 16px !important;
   font-family: raleway !important;
   font-weight: bold !important;
   max-width: 250px;
}

.container-block-paragraph-mobile {
    display: block;
    height: 15%;
    padding: 10px 0;
}

@media all and (min-width: 768px) {
    .container-bandeau-section {
        margin-top: 0vh;
    }
    .container-bandeau-without-children, .container-bandeau-with-children {
       margin-bottom: 30px;
       height: 35vh;
    }
    .container-text-title {
        font-size: 32px;
        color:#ffffff;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .container-text-paragraph {
        max-Width: 500px;
        color:#ffffff;
        margin-bottom: 10px;
     }

    .projets {
        background-image: linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 40%), url('/public/img/projets/groupe_personnes_v2.jpg');
    }
    .notre-mission {
        background-image: linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), url('/public/img/nos-engagements/homme_souriant_ruelle_v2.jpg');
    }
    .home-gradient {
        background-image:linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 90%), url("/public/img/home/place_vue_aerienne_v2.jpg");
    }
    .secteur-public {
        background-image:linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 90%), url("/public/img/colloc/coloc_header_cropped_v2.jpg");
    }
}

@media all and (min-width: 992px) {
    .container-bandeau-without-children, .container-bandeau-with-children  {
        margin-bottom: 10vh;
        height: 40vh;
     }

    .container-bandeau-with-children {
        height: 40vh;
        max-height: inherit;
        min-height:inherit;
    }

    .margin-bottom-with-p {
        margin-bottom: 3vh;
    }

    .container-text-paragraph {
       font-size: 20px;
       max-Width: 560px;
       margin-bottom: 20px;
    }

    .container-children {
        display: flex;
        align-items: center;
    }

    .notre-mission {
        background-image: linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), url('/public/img/nos-engagements/homme_souriant_ruelle_v2.jpg');
    }
    .home-gradient {
        background-image:linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 65%), url("/public/img/home/place_vue_aerienne_v2.jpg");
    }
    .secteur-public {
        background-image:linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 65%), url("/public/img/colloc/coloc_header_cropped_v2.jpg");
    }
    .projets {
        background-image: linear-gradient(to top right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 40%), url('/public/img/projets/groupe_personnes_v2.jpg');
        margin-bottom: 30px;
    }
}
