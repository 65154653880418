.abstract-color{
    color:#7F7F7F;
}

.flex-center{
    display: flex;
    justify-content: center;
}

.no-switch-link{
    margin-bottom: 1em;
}

.don-progress-bar{
    margin-top: 10px !important;
}

.don-progress-bar-text-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size:14px; 
    color:#404040;
}

.don-progress-text-attempted {
    color: #048A00;
    font-weight: bold;
    display: flex;
}
.don-attempted-objectif-icon {
    width:18px;
    margin-right:3px;
}
.don-progress-amount{
    font-weight: bold;
}

.don-progress-text-search {
    color: #7F7F7F;
}

.don-main-container{
    display: flex;
    flex-direction:column ;
    height: 100%;
    padding-top: 5px;
    justify-content: center;
}

.don-collected-money-block {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}
/* Boutons */
.don-main-button-container-when-no-switch{
    margin-bottom: 1rem;
}

.don-main-button-container-when-switch{
    margin-bottom: .6rem;
}

/* Bouton uniquement en cas d'adresse mail à valider */
.don-button-mail-action{
    margin-bottom: 1em !important;
}

.don-switch-link{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1em;
}

.don-paragraph-info-mecenat{
    text-align: center;
}

/* Informations du don */
.don-caracteristiques-bloc{
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 0 15px;
   margin-bottom: 1em;
}

.don-caracteristiques-bloc-title{
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 0 !important;
 }

 .don-caracteristiques-bloc-title-span{
    margin-left: 10px;
 }

/* Liens en bleu */
.don-informations-links{
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: .9em;
}

@media all and (min-width:768px){
    .don-main-container{
        padding-top: 1px;
    }

    .don-collected-money-block {
        font-size: 20px;
    }

    .don-progress-bar-text-container{
        font-size: 12px;
    }

    .don-attempted-objectif-icon {
        width:14px;
        margin-right:2px;
    }
}

@media all and (min-width:992px){
    .don-progress-text-attempted {
        align-items: center;
    }
    .don-attempted-objectif-icon {
        width:15px;
    }
}

@media all and (min-width:1200px){
    .don-collected-money-block{
        font-size: 22px;
    }

    .don-main-button-container-when-no-switch{
        margin-bottom: 1.4rem;
    }

    .don-caracteristiques-container{
        margin-bottom: 0.9em;
    }
    .don-progress-bar-text-container{
        font-size: 14px;
    }
    .don-attempted-objectif-icon {
        width:20px;
        margin-right:3px;
    }
}