#page-home{
    font-family: Raleway, sans-serif;

    h2 {
        font-family: Raleway, sans-serif;
    }

    .flex-centered-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .standard-section-spacing {
        padding: 35px 0;
        margin-top: 30px;
    }

    .custom-section-spacing {
        padding: 35px 0;
        margin-top: 70px;
    }
    
    .btn-call-to-action {
        font-family: Raleway;
        font-weight: bold;
        color:black;
        font-size: 16px;
    }
    .reversed-mobile-section{
       margin: 45px 0px;
    }
    .section-header-title {
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
    }

    .standard-section-container {
        margin: 0 auto;
        max-width: 90%;
    }
    .avantage-section-illustration-column{
        padding:0;
    }
    .avantage-section-illustration-container-right{
        display: flex;
        justify-content: flex-end;
    }

    .avantage-section-illustration-container-left{
        display: flex;
        justify-content: start;
        justify-content: center;
    }
    .avantage-section-title {
        font-family: Raleway;
        font-weight: bold;
        font-size: 24px;
        color:black;
    }

    .avantage-section-description {
        font-family: Raleway;
        font-size: 20px;
        color: black;
    }

    .social-proof-section{
        padding-top: 35px;
    }

    .media-section {
        padding: 30px 28px;
        max-width: 90%;
        margin: 0 auto;
        background-color: #005877;
        border-radius: 4px;
        color: #ffffff;
    }
    .media-section-header {
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
    }
    .media-section-description {
        color: #ffffff;
        font-size: 16px;
        padding: 5px;
        margin-top: 26px;
    }
    .video-section {
        width: 66%;
        height:350px;
    }

    .partners-section-text-container-paragraph {
        width:85%;
        font-size: 20px;  
        color:black;     
    }

    .avis-card-block {
        padding: 5px;
    }
    .avis-text-paragraph {
        color: black;
        font-size: 16px;
        font-style: italic;
    }
    .avis-name-content {
        border-top: none;
        display: flex;
        align-items: flex-end;
    }
   
}

@media all and (max-width: 768px) {
    #page-home{
        .section-header-title {
            display: flex;
            justify-content: start;
            margin-bottom: 35px;
        }
        
        .custom-section-spacing {
            padding: 25px 0;
            margin-top: 25px;
        }

        .reversed-mobile-section{
            flex-direction: column-reverse;
        }
        .standard-section-container {
            margin: 0 auto;
            max-width: 100%;
        }
        .avantage-section-illustration-container-right,.avantage-section-illustration-container-left{
            justify-content: center;
        }
        .avantage-section-title {
            font-size: 20px;
        }
        .avantage-section-description {
            font-family: Raleway;
            font-size: 16px;
        }

        .media-section {
            max-width: 100%;
        }

        .social-proof-section{
            padding-top: 5px;
        }
        .avantage-section-illustration-container-left img, .avantage-section-illustration-container-right img {
            max-width: 300px;
        }

        .video-section {
            width: 100%;
            height:300px;
        }


        .partners-section-logo-container {
            display: flex;
            justify-content: center;
            height: 100px;
        }

        .partners-section-text-container-paragraph {
            width:100%;
            font-size: 16px;
        }
        .avis-card-block {
            width: 100%;
        }
    }

}
@media all and (min-width: 768px){
    .avis-card-block {
        height: 353px;
    }
}
@media all and (min-width: 992px){
    .avis-text-paragraph-first-element {
        margin-bottom: 25px;
    }
}

@media all and (min-width: 1200px) {
    #page-home{
        .avantage-section-illustration-container-left img, .avantage-section-illustration-container-right img {
            max-width: 400px;
        }
        .avantage-section-title {
            margin-bottom: 25px;
        }
    
    }
}