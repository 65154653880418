.input-flex-container {
  display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 100px;
	position: relative;
	z-index: 0;
}
	
.timeline-input {
  width: 20px;
	height: 20px;
	background-color: #2C3E50;
	position: relative;
  border-radius: 50%;
  margin: 20px;
}

.timeline-input:hover {
  cursor: pointer;
}
    
.timeline-input::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2C3E50;
  width: 0;
  height: 5px;
  max-width: 50px;
}

.timeline-input::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2C3E50;
  width: 33vw;
  height: 5px;
}

.last-input::after {
  width: 24vw !important;
  height: 8px !important;
}
		
.timeline-input.active {
  background-color: #2C3E50;
}
		
    
.timeline-input::before {
  background-color: #2C3E50
}
			
timeline-input::after {
  background-color: #AEB6BF
}
			
.timeline-input.active   span {
  font-weight: 700
}
			
.timeline-input span::before {
  font-size: 13px
}
				
.timeline-input span::after {
  font-size: 15px;
}
			
.timeline-input span {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.timeline-input span::after {
  visibility: visible;
  position: absolute;
  left: 50%;
}

.timeline-input span::before {
  visibility: visible;
  position: absolute;
  left: 50%;
}
    
.timeline-input span::after {
  content: attr(data-year);
  top: 25px;
  transform: translateX(-50%);
  font-size: 14px;
}
  
.timeline-input span::before {
  content: attr(data-info);
  top: -65px;
  width: 70px;
  transform: translateX(-5px) rotateZ(-45deg);
  font-size: 12px;
  text-indent: -10px;
}
    
	
.description-flex-container {
  width: (0.8 * 100vw);
	font-weight: 400;
	font-size: 22px;
	margin-top: 100px;
  max-width: 1000px;
}

.description-flex-container p {
		margin-top: 0;
    display: none;
}

.description-flex-container p.active {
  display: block
}
	
@media (min-width: 1000px / (0.8 * 100vw)) {
  .timeline-input::before {
    left: -(1000px / 20) + (25px / 2)
  }
    
  .timeline-input::after {
    right: -(1000px / 20) + (25px / 2)
  }
}
	
@media (max-width: 850px) {
  .timeline-input {
    width: 17px;
    height: 17px;
  }

  .timeline-input::before::after {
    height: 3px
  }
}

.timeline-input.active ~ .timeline-input, .timeline-input.active ~ .timeline-input::before, .timeline-input.active ~ .timeline-input::after {
  background-color: #AEB6BF;
}

.timeline-input.active::after {
  background-color: #AEB6BF;
}
