.feedback-container-block{
    display: flex;
    align-items: center;
}

.feedback-name{
    margin-left: 10px !important;

    color: #005877;
    font-size: 16px;
    font-weight: bold;
}

.feedback-image-container{
    border: 1px transparent;
    border-radius: 40px;
    max-height:50px;
    max-width:50px;
}

.feedback-image{
    object-fit: contain;
    clip-path: ellipse(50% 50%);
}