/******* styles page engagements V2 *******/

#engagements-page-container {
    font-family: Raleway, sans-serif;

    h2{
        font-family: Raleway; 
        font-weight: bold;
    }
    
    .btn-call-to-action {
        font-family: Raleway;
        font-weight: bold;
        color:black;
        font-size: 16px;
    }

    .h3-as-H2style{
        font-family: raleway;
        font-size: 24px;
    }

}

.engagements-main-image-container{
    margin-top: 32px;
}

.engagements-main-image{
    border-radius: 10px;
    max-height: 440px;
}

.engagements-paragraph{
    font-size: 20px;
}

.standard-paragraph{
    font-size: 16px;
}

@media all and (max-width:768px) {
    #engagements-page-container{
        .engagements-list-middle-section{
            flex-direction: column-reverse;
            margin: 40px 0px;
        }
        .main-title-container-title{
            font-size: 32px;
            line-height: 1;
        }
        .engagements-paragraph{
            font-size: 16px;
        }
        .standard-paragraph{
            font-size: 14px;
        }
        .h3-as-H2style{
            font-family: raleway;
            font-size: 20px;
        }
    }
}

/** Section images-textes illustrations **/

.engagements-list-section{
    padding-top: 35px;
    margin-top: 10vh;
}
.engagements-list-container {
    margin: 0 auto;
    width: 100%;
}

.engagements-list-middle-section{
    margin: 30px 0px;
}
.engagement-block-image{
    display: flex;
    justify-content: center; 
}

.engagement-block-image-reversed-section{
    display: flex;
    flex-direction: column-reverse;
    margin: 30px 0;
    justify-content: space-between;

}

.engagement-block-image-reversed-section-paragraph{
    padding: 14px 0;
}

.engagement-block-image-reversed-section-container-of-img{
    margin: auto;
    padding: 14px 0;
}

@media all and (min-width: 768px) {
    .engagements-list-container {
        width: 90%;
    }
    .engagement-block-image-reversed-section{
        flex-direction: row;
        margin: 30px 0; 
        width: 100%;
        height: 197px;
    }

    .engagement-block-image-reversed-section-paragraph{
        max-width: 492px;
        display: flex;
        padding-right: 47px;
        align-items: center;
    }

    .flexbox-paragraph{
        margin-right: 47px;
    }
    .engagement-block-image-reversed-section-container-of-img{
        min-width: 282px; 
        padding: 0 14px;
    }
    .engagement-block-image-reversed-section-img{
        object-fit: cover;
    }

}

@media all and (min-width: 992px) {
    .engagements-list-middle-section{
        margin: 35px 0px;
    }

    .engagement-block-image-reversed-section{
        max-height: auto;
        min-height: 275px;
    }
    .engagement-block-image-reversed-section-paragraph{
        max-width: 600px;
        padding-right: 85px;
    }

    .engagement-block-image-reversed-section-container-of-img{
        max-width: 434px;
        height: 275px;
    }
    
    .flexbox-paragraph{
        margin-right: 0px;
    }
}

@media all and (min-width: 1200px) {
    .engagement-block-image-reversed-section{
        min-height: 278px;
    }
    .engagement-block-image-reversed-section-paragraph{
        max-width: 750px;
    }

    .engagement-block-image-reversed-section-container-of-img{
        max-width: 433px;
        width:auto;
        height: auto;
    }
    .flexbox-paragraph{
        margin-right: 60px;
    }
}

/** Section weAre **/

#weAre {

    .weAre-main-title {
        margin-bottom: 25px;
        text-align: center;
    }

    .weAre-row {
        margin-top: 15px;
    }

    @media all and (min-width: 768px) {
        .weAre-row {
            justify-content: space-between;
        }
        .weAre-column {
            max-width: 330px;
        }
        .weAre-column-container-img {
            width: 206px;
            height: 275px;
        }
    }

    @media all and (min-width: 992px) {
        .weAre-column-container-img {
            width: 272px;
            height: 362px;
        }
    }
    @media all and (min-width: 1200px) {
        .weAre-column-container-img {
            width: 302px;
            height: 402px;
        }
    }
}

@media all and (max-width: 768px) {
    #weAre {
        .weAre-column-middle{
            margin:35px 0px !important;
        }

        .weAre-column-container-img {
            
            display: flex;
            align-items: center;
        }
        
        .weAre-column-img {
            /* object-fit: FILL; */
                max-width: 338px;
                max-height: 450px;
        }
        .weAre-main-title {
            text-align: start;
        }
    }
}

/** Section testimony **/

.engagements-testimony-container {
    margin-top:10vh;
}

/** Section fondateurs **/

#fondateurs {
    margin-top: 100px;

    h2 {
        text-align: start;
    }

    .fondateurs-img {
        margin: auto;
        margin-top: 30px;
    }
    .fondateurs-column {
        margin-top: 30px;
    }
    @media all and (min-width: 768px) {
        margin-top: 150px;

        h2 {
            text-align: center;
        }
    
        .fondateurs-row {
            justify-content: space-between;
        }
        .fondateurs-column {
            max-width: 330px;
        }
        .fondateurs-img {
            margin: auto;
            margin-top: 50px;
        }
    }
}

/** Section objectif développement durable **/

.section-objectif-dev-durable-title {
    margin-top: 80px;
}

/** Communauté **/

.communaute-block{
    margin-top: 100px;
    margin-bottom: 80px;
}