#bandeau-container{
    display: flex;
    flex-direction:column;
    padding: 10px 20px;
    border-radius: 0.28571429rem;
    margin-bottom: 20px;
}

.alert{
    background-color: #fcf8e3;
    color: #8a6d3b;
}
.information {
    background-color: #d9edf7;
    color: #005879;
    border: 1px solid #005879;
}
.danger{
    background-color: #f2dede;
    color: #a94442;
}

.informations-bloc{
    display: flex;
}

.informations-bloc-icon-container{
   margin-right: 20px;
   border: none;
   margin-bottom: auto;
   margin-top: 10px;
}

.informations-bloc-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.informations-bloc-text-title-withIcon{
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 5px;
}
.informations-bloc-text-title-withoutIcon{
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
}
.informations-bloc-text-paragraph{
    font-size: 16px;
}
.items-center{
    align-items: center;
}

.children-container{
    margin-top: 10px;
}

.self-center{
    align-self: center;
}
.self-start{
    align-self: self-start;
}
.self-end{
    align-self: self-end;
}

@media all and (max-width: 1200px) {
    .children-container{
        margin-top: 25px;
    }
}
