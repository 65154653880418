.abstract-color{
    color:#7F7F7F;
}

.flex-center{
    display: flex;
    justify-content: center;
}

.no-switch-link{
    margin: 1em 0
}

.financement-main-container{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    height:100%;
}

.financement-collected-money-block {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}

.financement-progress-bar-container {
    margin-top: 5px;
}

.financement-progress-bar-text-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size:14px; 
    color:#404040;
}

.progress-text-attempted {
    color: #048A00;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.progress-amount{
    font-weight: bold;
}

.progress-text-search {
    color: #7F7F7F;
}

.attempted-objectif {
    max-height: 38px;
}
.attempted-objectif-icon {
    width:18px;
    margin-right:3px;
}

.financement-switch-link {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding-bottom: 10px;
}

.financement-close-text-area{
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    color: #085c74;
}

/* Bouton principal */
.financement-container-button-main-action{
    margin-top: 1em;
    margin-bottom: 1em;
}

/* Blocs éléments caractéristiques*/
.financement-caracteristiques-block {
    display: flex;
    flex-direction: row; 
    align-items: center;
    font-size: .9em;
    margin-bottom: .5em;
}

.financement-caracteristiques-block-element {
    display: flex;
    width: 50%;
    flex-direction: column;
}
.financement-caracteristiques-block-element-title-span {
    font-weight: bold;
}
/* Liens d'information*/
.financement-informations-links{
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: .9em;
}


@media all and (min-width:768px){
    .no-switch-link{
        margin: 0.7em 0
    }
    
    .financement-collected-money-block {
        font-size: 20px;
    }

    .attempted-objectif {
        max-height: 48px;
    }
    .attempted-objectif-icon {
        width:14px;
        margin-right:2px;
    }

    .financement-progress-bar-text-container {
        font-size:12px; 
    }
}

@media all and (min-width:992px){
    .financement-collected-money-block {
        font-size: 20px;
    }
    .progress-text-attempted {
        display: flex;
    }
}

@media all and (min-width:1200px){
    .financement-collected-money-block {
        font-size: 22px;
    }

    .financement-caracteristiques-block {
        margin-bottom: .9em;
    }

    .financement-switch-link {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .no-switch-link{
        margin: 0.9em 0;
    }

    .financement-close-text-area{
        font-size: 1.2rem;
    }

    .attempted-objectif {
        max-height: 38px;
    }
    .attempted-objectif-icon {
        width:20px
    }

    .financement-progress-bar-text-container {
        font-size:14px; 
    }

}