/* Partie block supérieur */
.summary-header-block{
    height: 15%;
}

.summary-header-text-area{
    display: flex;
    align-items: center;
    justify-content:center; 
    margin-top: 0.5rem;
}

.summary-header-span-day{
    font-size:1.2em;
    font-weight: bold;
}

.summary-header-date-block{
    display: flex;
    flex-direction: column; 
    justify-content:space-around;
    align-items: center;
}

.summary-header-span-end-date{
    font-size: 0.9em;
    display: flex;
    justify-content:center;
    align-items: center;
}

/* Bloc info central */
.summary-central-block{
    height:80%;
    padding:0px 10px;
    position: relative;
}

@media all and (min-width: 768px) {
    /* Partie block supérieur */
    .summary-header-block{
        height: 10%;
    }

    .summary-header-text-area{
        margin-top: 2px;
    }
    
    /* Bloc info central */
    .summary-central-block{
        height:80%;
        padding:0px 3px;
    }
}

@media all and (min-width: 1200px) {
    /* Partie block supérieur */

    .summary-header-text-area{
        margin-top: 0.5rem;
    }
}

