.summary-don-header-block{
    height: 12%;
    /* margin-bottom: 15px !important; */
}

.summary-don-header-text-area{
    display: flex;
    align-items: center;
    justify-content:center; 
    margin-top: 0.5rem;
}

.summary-don-header-date-block{
    display: flex;
    flex-direction: column; 
    justify-content:center;
    align-items: center;
    height: 100%;
}

.summary-header-span-end-date{
    font-size: 0.9em;
    display: flex;
    justify-content:center;
    align-items: center;
}

.summary-don-header-span-day{
    font-size:1.2em;
    font-weight: bold;
}

/* Partie principale */

.summary-don-main-block{
    height: 88%;
    position: relative;
    padding: 0 10px;
}

@media all and (min-width:768px){
    .don-button-main-action{
        height: 35px;
    }
    .summary-don-header-block{
        height: 10%;
    }
    .summary-don-main-block{
        height: 90%;
    }
}


@media all and (min-width:1200px){
    .don-button-main-action{
        height: 45px;
    }
    .summary-don-header-block{
        height: 12%;
    }
    .summary-don-main-block{
        height: 88%;
    }
}
