@tailwind base;
@tailwind components;
@tailwind utilities;

/* Nouvelles polices */
@font-face {
    font-family: "Raleway";
    src: url('/fonts/Raleway-Regular.ttf');
}

@font-face {
    font-family: "Merriweather";
    src: url('/fonts/Merriweather-Regular.ttf');
}
@font-face {
    font-family: "Raleway-bold";
    src: url('/fonts/Raleway-Bold.ttf');
}


ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.container-app {
    display: block;
    margin: 15px;
    width: 100%;
}

.menu-container {
    height: 60px;
    width: 100%;
    background-color: #005870;
}

.menu-shadow {
    box-shadow: none;
    -webkit-box-shadow: 0px 1px 10px 4px rgba(0, 0, 0, 0.3);
}

.menu-item {
    display: flex;
    margin-right: 100px;
    margin-left: 20px;
    height: auto;
    align-items: center;
}

.menu-item a {
    color: #fff;
}

.form-control {
    display: block;
    width: auto;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.button-bar {
    display: inline-block;
    margin-bottom: 20px;
}

.button-action {
    display: inherit;
    margin-right: 10px;
}

.ui.selection.dropdown {
    border: 1px solid #ccc !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    min-height: 0em !important;
}

.ui.progress {
    height: auto;
}

.ui.progress .bar {
    min-width: 0em !important;
}

.form-control-no-padding-no-border {
    display: block;
    width: auto;
    height: 34px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    margin-bottom: 20px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-group {
    margin-bottom: 15px;
}

.btn {
    color: #fff;
    background-color: #005870;
    border-color: #2e6da4;
    min-width: 100px;
}

.btn-primary {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn-secondary {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.42857143;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: raleway;
    color: #000000;
    background-color: #FF914D;
    min-width: 100px;

    &:hover {
        color: #222222;
    }
}

.btn-primary-inverted {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.42857143;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #005870;;
    border-radius: 4px;
    font-family: raleway;
    color: #005870;
    background-color: #ffffff;
}

.btn-project-disabled{
    color: #9C9C9C;
    background-color: #fff;
    border-color: #9C9C9C;
    min-width: 100px;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 2px solid ;
    border-radius: 4px;
}

.raleway-and-16 {
    font-size: 16px;
    font-family: raleway;
}

@media all and (min-width: 768px) {
    .stretch-on-desktop {
        min-width: 250px;
        min-height: 40px;
    }
}

.page-header {
    display: block;
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: bold;
    padding-left: 10px;
}

#root {
    width: 100%;
}

.list-item {
    -webkit-box-shadow: -1px 5px 10px 5px rgba(0, 0, 0, 0.3);
    box-shadow: none;
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 15px;
    font-size: 20px;
}

.list-item-line {
    margin-bottom: 6px;
    font-size: 16px;
}

.secondary-color {
    color: #692244;
}

.app-container {
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
    overflow: hidden;
}

.auth-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.popup-container-content {
    width: auto !important;
    min-width: 50% !important;
    max-width: 70% !important;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-height: 90% !important;
    overflow-y: scroll;
    overflow-x: hidden;
}

.vertical-divider {
    border-right: 1px solid;
    height: auto;
    padding-right: 25px !important;
}

.vertical-divider-right {
    padding-left: 25px !important;
}

.margin-to-button-at-right {
    margin-right: 15px;
}


@media (max-width: 767px) {
    .vertical-divider {
        border-right: 0px;
        height: auto;
    }

    .vertical-divider-right {
        padding-left: 15px !important;
        margin-top: 40px;
    }

    .ui.secondary.menu {
        flex-wrap: wrap;
    }

    .ui.secondary.menu .item {
        flex-grow: 1;
    }

    .resp-padding-bottom {
        padding-bottom: 16px;
    }

    .btn-primary, .btn-primary-inverted, .btn-secondary {
        font-size: 14px;
    }
    .margin-to-button-at-right {
        margin-right: 10px;
    }
}

@media (max-width: 900px) {
    .last-section {
        margin: 0rem 1.5rem;
    }
}

@media (max-width: 487px) {
    .agrement-section .image {
        width: 100% !important;
    }
}

.horizontal-timeline {
    margin-bottom: 50px;
}

.popup-error-message {
    font-size: 20px;
    margin-bottom: 30px;
}

.popup-close-button {
    display: flex;
    justify-content: center;
}

.checkbox-horizontal-list {
    margin-right: 5px;
}

.bloc-info {
    padding: 40px !important;
}

html,
body {
    height: auto !important;
}

body {
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    color: #424949;
    background: #fcfaf8 !important;
}

body h1 {
    font-family: "merriweather";
}

h1 {
    height: auto;
    margin: 60px 0;
    font-family: "raleway";
}

li h1 {
    font-size: 16px;
    height: auto;
    margin: 0 !important;
}

li p {
    font-size: 13px;
    height: auto;
    margin: 0 !important;
}

li.last-input {
    float: "right";
}

h1 span {
    white-space: nowrap;
}

.flex-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.description-flex-container {
    width: (0.8 * 100vw);
    font-weight: 400;
    font-size: 22px;
    margin-top: 100px;
    max-width: 1000px;
}

.description-flex-container p {
    margin-top: 0;
    display: none;
}

.description-flex-container p.active {
    display: block;
}

/* @media (min-width: 1000px / (0.8 * 100vw)) {
    .input::before {
        left: -(1000px / 20) + (25px / 2);
    }

    .input::after {
        right: -(1000px / 20) + (25px / 2);
    }
} */

@media (max-width: 850px) {
    .input {
        width: 17px;
        height: 17px;
    }

    .input::before::after {
        height: 3px;
    }
}

.input.active ~ .input,
.input.active ~ .input::before,
.input.active ~ .input::after {
    background-color: #aeb6bf;
}

.input.active::after {
    background-color: #aeb6bf;
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}
blockquote p {
    display: inline;
}

