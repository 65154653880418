.container-btn {
    display: flex;
    margin-bottom: 6vh;
}

@media all and (min-width: 768px) {
    .container-btn {
        margin-top: 8vh;
        margin-bottom: 3vh;
    }
}