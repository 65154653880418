.react-slideshow-container .containerSlide {
    margin: auto;
}

.react-slideshow-container img {
  width: 100%;
  height: 100%;
}

.react-slideshow-container .loading {
  background: transparent url("./wireframe.png") center no-repeat;
}