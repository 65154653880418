.testimony-container{
    padding: 26px;
    max-width: 100%;
    margin: 0 auto;
    background-color:#005877;
    border-radius: 4px;
    color: #ffffff;
}

.testimony-image {
    width: 100%; 
    max-width : 175px !important; 
    border-radius: 50%;
}

.testimony-comment-paragraph, .testimony-comment-paragraph-more-padding {
    font-size: 16px; 
    position: relative ;
}

.testimon-quote-mark {
    position: absolute;
    top: 6px;
    left: -26px;
    font-size: 200px;
    color: #ffffff;
    opacity : 0.4;
}

@media all and (min-width: 1200px) {
    .testimony-comment-paragraph{
        padding-top: 5px;
        padding-right: 55px;
    }
    .testimony-comment-paragraph-more-padding {
            padding-top: 15px;
            padding-right: 55px;
    }
}

@media all and (max-width: 576px){
    .testimon-quote-mark {
        top: -20px;
    }
}

@media all and (min-width: 768px) {
    .testimony-container{
        max-width: 90%;
    }
}