.ui.toggle.checkbox.checked.info-red{
    label {
      &::before{
          background-color: #ca1616 !important;
      }
    }   
  }

  .ui.toggle.checkbox.info-green{
    label {
      &::before{
          background-color: #1f9a0f;
      }
    }   
  }
