.section-blue-box {
    padding: 30px 28px;
    max-width: 90%;
    margin: 0 auto ;
    background-color:#005877;
    border-radius: 4px;
    color: #ffffff;
}

.container-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blue-box-img {
    height: 110px;
    width: 110px;
}

@media all and (max-width: 768px){
    .section-blue-box {
        max-width: 100%;
    }
}

@media all and (min-width: 768px){
    .blue-box-img {
        height: auto;
        width: auto;
    }
}

@media all and (min-width: 992px){
    .blue-box-img {
        height: 110px;
        width: 110px;
    }
}